import Layout from '../components/Layout';
import HomePage from '../components/HomePage';

const IndexPage = () => (
  <Layout>
    <HomePage />
  </Layout>
);

export default IndexPage;
